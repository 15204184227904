<template>
  <div id="batchInstructionNotice">
    <el-dialog
      :title="batchInstructionNoticeFormTitle"
      width="1200px"
      :visible.sync="batchInstructionNoticeDialogVisible"
      :close-on-click-modal="false"
      @close="batchInstructionNoticeDialogClose"
    >
      <div id="pdfDom">
        <el-form
          ref="batchInstructionNoticeFormRef"
          :model="batchInstructionNoticeForm"
          :rules="batchInstructionNoticeFormRules"
          label-position="right"
          label-width="120px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="产品编号" prop="productNo">
                <el-select
                  v-model="batchInstructionNoticeForm.productNo"
                  placeholder="请选择产品"
                  clearable
                  filterable
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                  @change="materialProductStockChange"
                >
                  <el-option
                    v-for="item in materialProductStockList"
                    :key="item.id"
                    :label="item.productNo"
                    :value="item.productNo"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="产品名称" prop="productName">
                <el-input
                  v-model="batchInstructionNoticeForm.productName"
                  placeholder="请输入产品名称"
                  clearable
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="型号规格" prop="spec">
                <el-input
                  v-model="batchInstructionNoticeForm.spec"
                  placeholder="请输入型号规格"
                  clearable
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="计划数量" prop="planQuantity">
                <el-input
                  v-model="batchInstructionNoticeForm.planQuantity"
                  placeholder="请输入计划数量"
                  clearable
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="生产时间" prop="productionTime">
                <el-date-picker
                  v-model="batchInstructionNoticeForm.productionTime"
                  placeholder="请选择生产时间"
                  value-format="yyyy-MM-dd"
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="预计完成时间" prop="finishTime">
                <el-date-picker
                  v-model="batchInstructionNoticeForm.finishTime"
                  placeholder="请选择预计完成时间"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="生产批号" prop="productionBatchNo">
                <el-input
                  v-model="batchInstructionNoticeForm.productionBatchNo"
                  placeholder="请输入生产批号"
                  clearable
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="生产目的" prop="purpose">
                <el-radio-group
                  v-model="batchInstructionNoticeForm.purpose"
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                >
                  <el-radio :label="1"> 订单 </el-radio>
                  <el-radio :label="2"> 库存 </el-radio>
                  <el-radio :label="3"> 其他 </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="生产负责人" prop="manager">
                <el-input
                  v-model="batchInstructionNoticeForm.manager"
                  placeholder="请输入生产负责人"
                  clearable
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="签发时间" prop="issuanceTime">
                <el-input
                  v-model="batchInstructionNoticeForm.issuanceTime"
                  placeholder="请输入签发时间"
                  clearable
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="原辅料接收计划">
            <vxe-toolbar
              v-if="
                batchInstructionNoticeFormTitle === '新增批生产指令通知单' ||
                batchInstructionNoticeFormTitle === '修改批生产指令通知单'
              "
            >
              <template #buttons>
                <vxe-button @click="insertRowEvent()"> 新增 </vxe-button>
              </template>
            </vxe-toolbar>
            <vxe-table
              ref="xTable"
              border
              resizable
              show-overflow
              keep-source
              :data="materialPlanList"
              :edit-config="{ trigger: 'click', mode: 'cell' }"
            >
              <vxe-table-column field="materialNo" title="原辅料编号">
                <template v-slot="{ row, column }">
                  <el-select
                    v-model="row[column.property]"
                    placeholder="请选择原辅料"
                    clearable
                    filterable
                    @change="materialPurchaseStockChange(row)"
                  >
                    <el-option
                      v-for="item in materialPurchaseStockList"
                      :key="item.id"
                      :label="item.materialNo"
                      :value="item.materialNo"
                    >
                      <span style="float: left">{{ item.materialNo }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.productName }}</span
                      >
                    </el-option>
                  </el-select>
                </template>
              </vxe-table-column>
              <vxe-table-column
                field="rawMaterialName"
                title="原辅料名称"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="spec"
                title="型号规格"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="unit"
                title="单位"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="realStock"
                title="库存数量"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="requisitionQuantity"
                title="接收数量"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                field="remarks"
                title="备注"
                :edit-render="{ name: '$input', props: { clearable: true } }"
              />
              <vxe-table-column
                v-if="
                  batchInstructionNoticeFormTitle === '新增批生产指令通知单' ||
                  batchInstructionNoticeFormTitle === '修改批生产指令通知单'
                "
                title="操作"
                width="100"
              >
                <template #default="{ row }">
                  <template>
                    <vxe-button @click="removeRowEvent(row)"> 删除 </vxe-button>
                  </template>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
          <el-row>
            <el-col :span="8">
              <el-form-item label="备注" prop="remarks">
                <el-input
                  v-model="batchInstructionNoticeForm.remarks"
                  placeholder="请输入备注"
                  clearable
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '新增批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !==
                      '修改批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="接收结果" prop="receiveResult">
                <el-radio-group
                  v-model="batchInstructionNoticeForm.receiveResult"
                  :disabled="
                    batchInstructionNoticeFormTitle !==
                      '接收批生产指令通知单' &&
                    batchInstructionNoticeFormTitle !== '批生产指令通知单详情'
                  "
                >
                  <el-radio :label="1"> 未接收 </el-radio>
                  <el-radio :label="2"> 已接收 </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="batchInstructionNoticeDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="batchInstructionNoticeFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="batchInstructionNoticeFormTitle === '批生产指令通知单详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品编号">
        <el-input
          v-model="searchForm.productNo"
          placeholder="请输入产品编号"
          clearable
        />
      </el-form-item>
      <el-form-item label="产品名称">
        <el-input
          v-model="searchForm.productName"
          placeholder="请输入产品名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['BATCH_NOTICE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="batchInstructionNoticePage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="planQuantity" label="计划数量" />
      <el-table-column label="生产时间">
        <template slot-scope="scope">
          <span v-if="scope.row.productionTime">{{
            scope.row.productionTime.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="预计完成时间">
        <template slot-scope="scope">
          <span v-if="scope.row.finishTime">{{
            scope.row.finishTime.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productionBatchNo" label="生产批号" />
      <el-table-column label="生产目的">
        <template slot-scope="scope">
          <span v-if="scope.row.purpose === 1">订单</span>
          <span v-if="scope.row.purpose === 2">库存</span>
          <span v-if="scope.row.purpose === 3">其他</span>
        </template>
      </el-table-column>
      <el-table-column prop="manager" label="生产负责人" />
      <el-table-column prop="issuanceTime" label="签发时间" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="receiver" label="接收人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.receivedDate">{{
            scope.row.receivedDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="接收结果">
        <template slot-scope="scope">
          <span v-if="scope.row.receiveResult === 1">未接收</span>
          <span v-if="scope.row.receiveResult === 2">已接收</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
            v-if="
              checkPermission(['BATCH_NOTICE_DELETE']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="
              checkPermission(['BATCH_NOTICE_UPDATE']) && scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="
              checkPermission(['BATCH_NOTICE_RECEIVE']) &&
              scope.row.status === 1
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReceive(scope.$index, scope.row)"
          >
            接收
          </el-button>
          <el-button
            v-if="
              checkPermission(['BATCH_NOTICE_GENERATE']) &&
              scope.row.status === 2 &&
              scope.row.receiveResult === 2
            "
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleGenerate(scope.$index, scope.row)"
          >
            生成领料单
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="batchInstructionNoticePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addBatchInstructionNotice,
  deleteBatchInstructionNotice,
  updateBatchInstructionNotice,
  selectBatchInstructionNoticeInfo,
  selectBatchInstructionNoticeList,
  receiveBatchInstructionNotice,
  generate,
} from "@/api/produce/batchInstructionNotice";
import {
  selectMaterialProductStockByNo,
  selectMaterialProductStockList,
} from "@/api/storage/materialProductStock";
import { selectMaterialPurchaseStockList } from "@/api/storage/materialPurchaseStock";
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      batchInstructionNoticeDialogVisible: false,
      batchInstructionNoticeFormTitle: "",
      batchInstructionNoticeForm: {
        id: "",
        productNo: "",
        productName: "",
        spec: "",
        planQuantity: "",
        productionTime: "",
        finishTime: "",
        productionBatchNo: "",
        purpose: "",
        manager: "",
        issuanceTime: "",
        remarks: "",
        receiveResult: "",
        status: "",
        materialPlanJson: "",
        taskId: "",
      },
      batchInstructionNoticeFormRules: {
        productNo: [
          {
            required: true,
            message: "产品编号不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      batchInstructionNoticePage: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productNo: "",
        productName: "",
      },
      materialPlanList: [],
      materialProductStockList: [],
      materialPurchaseStockList: [],
      pickerOptions: {},
    };
  },
  watch: {
    "batchInstructionNoticeForm.productionTime": function (val) {
      this.pickerOptions = {
        disabledDate(time) {
          return false;
        },
      };
      if (val) {
        this.pickerOptions = {
          disabledDate(time) {
            return time < new Date(val);
          },
        };
      }
    },
  },
  created() {
    selectBatchInstructionNoticeList(this.searchForm).then((res) => {
      this.batchInstructionNoticePage = res;
    });
    selectMaterialProductStockList({ type: 1 }).then((res) => {
      this.materialProductStockList = res.list;
    });
    selectMaterialPurchaseStockList({ type: 1 }).then((res) => {
      this.materialPurchaseStockList = res.list;
    });
  },
  methods: {
    batchInstructionNoticeDialogClose() {
      this.$refs.batchInstructionNoticeFormRef.resetFields();
      this.materialPlanList = [];
    },
    batchInstructionNoticeFormSubmit() {
      if (this.batchInstructionNoticeFormTitle === "批生产指令通知单详情") {
        this.batchInstructionNoticeDialogVisible = false;
        return;
      }
      this.$refs.batchInstructionNoticeFormRef.validate(async (valid) => {
        if (valid) {
          if (this.batchInstructionNoticeFormTitle === "新增批生产指令通知单") {
            // let detailList = this.$refs.xTable.getTableData().tableData
            // for (let detail of detailList) {
            //   if (!detail.realStock) {
            //     detail.realStock = 0
            //   }
            //   if (!detail.requisitionQuantity) {
            //     this.$message.warning({ message: '请填写接收数量', center: true })
            //     return
            //   }
            //   if (detail.realStock < detail.requisitionQuantity) {
            //     this.$message.warning({ message: detail.rawMaterialName + '库存不足', center: true })
            //     return
            //   }
            // }
            this.batchInstructionNoticeForm.id = "";
            this.batchInstructionNoticeForm.status = 1;
            this.batchInstructionNoticeForm.materialPlanJson = JSON.stringify(
              this.$refs.xTable.getTableData().tableData
            );
            await addBatchInstructionNotice(this.batchInstructionNoticeForm);
          } else if (
            this.batchInstructionNoticeFormTitle === "修改批生产指令通知单"
          ) {
            this.batchInstructionNoticeForm.materialPlanJson = JSON.stringify(
              this.$refs.xTable.getTableData().tableData
            );
            await updateBatchInstructionNotice(this.batchInstructionNoticeForm);
          } else if (
            this.batchInstructionNoticeFormTitle === "接收批生产指令通知单"
          ) {
            if (!this.batchInstructionNoticeForm.receiveResult) {
              this.$message.warning({
                message: "请选择接收结果",
                center: true,
              });
              return;
            }
            this.batchInstructionNoticeForm.status = 2;
            await receiveBatchInstructionNotice(
              this.batchInstructionNoticeForm
            );
          }
          this.batchInstructionNoticePage =
            await selectBatchInstructionNoticeList(this.searchForm);
          this.batchInstructionNoticeDialogVisible = false;
        }
      });
    },
    handleAdd() {
      this.batchInstructionNoticeFormTitle = "新增批生产指令通知单";
      this.batchInstructionNoticeDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteBatchInstructionNotice(row.id);
        if (
          this.batchInstructionNoticePage.list.length === 1 &&
          this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--;
        }
        this.batchInstructionNoticePage =
          await selectBatchInstructionNoticeList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.batchInstructionNoticeFormTitle = "修改批生产指令通知单";
      this.batchInstructionNoticeDialogVisible = true;
      this.selectBatchInstructionNoticeInfoById(row.id);
    },
    handleReceive(index, row) {
      this.batchInstructionNoticeFormTitle = "接收批生产指令通知单";
      this.batchInstructionNoticeDialogVisible = true;
      this.selectBatchInstructionNoticeInfoById(row.id);
    },
    handleInfo(index, row) {
      this.batchInstructionNoticeFormTitle = "批生产指令通知单详情";
      this.batchInstructionNoticeDialogVisible = true;
      this.selectBatchInstructionNoticeInfoById(row.id);
    },
    handleGenerate(index, row) {
      generate(row.id).then((res) => {
        selectBatchInstructionNoticeList(this.searchForm).then((res) => {
          this.batchInstructionNoticePage = res;
        });
      });
    },
    selectBatchInstructionNoticeInfoById(id) {
      selectBatchInstructionNoticeInfo(id).then((res) => {
        this.batchInstructionNoticeForm.id = res.id;
        this.batchInstructionNoticeForm.productNo = res.productNo;
        this.batchInstructionNoticeForm.productName = res.productName;
        this.batchInstructionNoticeForm.spec = res.spec;
        this.batchInstructionNoticeForm.planQuantity = res.planQuantity;
        this.batchInstructionNoticeForm.productionTime = res.productionTime;
        this.batchInstructionNoticeForm.finishTime = res.finishTime;
        this.batchInstructionNoticeForm.productionBatchNo =
          res.productionBatchNo;
        this.batchInstructionNoticeForm.purpose = res.purpose;
        this.batchInstructionNoticeForm.manager = res.manager;
        this.batchInstructionNoticeForm.issuanceTime = res.issuanceTime;
        this.batchInstructionNoticeForm.remarks = res.remarks;
        this.batchInstructionNoticeForm.receiveResult = res.receiveResult;
        this.batchInstructionNoticeForm.taskId = res.taskId;
        this.materialPlanList = res.materialPlanList;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      selectBatchInstructionNoticeList(this.searchForm).then((res) => {
        this.batchInstructionNoticePage = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      selectBatchInstructionNoticeList(this.searchForm).then((res) => {
        this.batchInstructionNoticePage = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      selectBatchInstructionNoticeList(this.searchForm).then((res) => {
        this.batchInstructionNoticePage = res;
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.checkPermission(["BATCH_NOTICE_RECEIVE"]) && row.status === 1) {
        return "review";
      }
      return "";
    },
    async insertRowEvent(row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1);
      await this.$refs.xTable.setActiveRow(newRow);
    },
    removeRowEvent(row) {
      this.$refs.xTable.remove(row);
    },
    materialProductStockChange(value) {
      if (value) {
        selectMaterialProductStockByNo(value).then((res) => {
          this.batchInstructionNoticeForm.productNo = res.productNo;
          this.batchInstructionNoticeForm.productName = res.productName;
          this.batchInstructionNoticeForm.spec = res.spec;
          this.materialPlanList = [];
          for (let item of res.detailList) {
            this.materialPlanList.push({
              materialNo: item.materialNo,
              rawMaterialName: item.productName,
              spec: item.spec,
              unit: item.unit,
              realStock: item.realStock,
            });
          }
        });
      } else {
        this.batchInstructionNoticeForm.productNo = "";
        this.batchInstructionNoticeForm.productName = "";
        this.batchInstructionNoticeForm.spec = "";
        this.materialPlanList = [];
      }
    },
    materialPurchaseStockChange(row) {
      if (row.materialNo) {
        let materialPurchaseStock = this.materialPurchaseStockList.find(
          (item) => item.materialNo === row.materialNo
        );
        row.rawMaterialName = materialPurchaseStock.productName;
        row.spec = materialPurchaseStock.spec;
        row.unit = materialPurchaseStock.unit;
      } else {
        row.rawMaterialName = "";
        row.spec = "";
        row.unit = "";
      }
    },
    getPdf() {
      let loading = "";
      let dom = document.getElementById("pdfDom");
      html2PDF(dom, {
        jsPDF: {
          unit: "px",
          format: "a4",
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true,
        },
        imageType: "image/jpeg",
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0,
        },
        output: `批指令生产通知单${this.batchInstructionNoticeForm.productionTime.substring(
          0,
          10
        )}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: "pdf加载中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
        },
        success: function (pdf) {
          pdf.save(this.output);
          loading.close();
        },
      });
    },
  },
};
</script>

<style></style>
