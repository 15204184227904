import axios from '@/common/axios'
import qs from 'qs'

export function addBatchInstructionNotice (data) {
  return axios({
    method: 'post',
    url: '/production/batchInstructionNotice/add',
    data: qs.stringify(data)
  })
}

export function deleteBatchInstructionNotice (id) {
  return axios({
    method: 'delete',
    url: '/production/batchInstructionNotice/delete/' + id
  })
}

export function updateBatchInstructionNotice (data) {
  return axios({
    method: 'put',
    url: '/production/batchInstructionNotice/update',
    data: qs.stringify(data)
  })
}

export function selectBatchInstructionNoticeInfo (id) {
  return axios({
    method: 'get',
    url: '/production/batchInstructionNotice/info/' + id
  })
}

export function selectBatchInstructionNoticeList (query) {
  return axios({
    method: 'get',
    url: '/production/batchInstructionNotice/list',
    params: query
  })
}

export function receiveBatchInstructionNotice (data) {
  return axios({
    method: 'put',
    url: '/production/batchInstructionNotice/updateReceive',
    data: qs.stringify(data)
  })
}

export function generate (id) {
  return axios({
    method: 'post',
    url: '/production/batchInstructionNotice/generate/' + id
  })
}
